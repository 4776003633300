<template>
  <div id="page-user-list">

    <vs-popup classContent="popup-example" title="Nouvel Utilisateur" :active.sync="popupUser">
      <!-- <vx-card title="Nouvel Utilisateur" class="mb-base"> -->

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="Personnel" class="w-full" v-model="IdPersonel" @change="OnSelectPersonnel" >
              <vs-select-item v-for="item in Personnels" :key="item._id" :value="item.id" :text="item.personnelFullName"/>
            </vs-select>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="fonction" class="w-full" v-model="fonction" >
              <vs-select-item v-for="item in fonctions" :key="item._id" :value="item.Libelle_Fonct" :text="item.Libelle_Fonct"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="Login" class="w-full" type="text" v-validate="'required'" name="Login" v-model="login" />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="Role" class="w-full" v-model="role" >
              <vs-select-item v-for="item in roles" :key="item._id" :value="item.libelle" :text="item.libelle"/>
            </vs-select>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="Mot de passe" class="w-full" type="password" v-validate="'required'" name="password" v-model="password" />
            <span class="text-danger text-sm" v-show="errors.has('pass')">{{ errors.first('pass') }}</span>
            <password-meter :password="password" />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="Confirme Mot de passe" class="w-full" type="password" v-validate="'required|confirmed:password'" data-vv-as="password" name="password_confirm" v-model="password_confirm" />
            <password-meter v-if="password == password_confirm" :password="password_confirm" />
          </div>
          <!-- <div class="vx-col w-full">
            <vs-button class="mt-5 w-full" color="success"  @click="generatePassword">Generate Password</vs-button>
          </div> -->
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatUser" >Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="IdPersonel = login = paswword = confirm_password = fonction = role = ''; status = false;">Annuler</vs-button>
          </div>
        </div>
      <!-- </vx-card> -->
    </vs-popup>
    <!-- <vx-card ref="filterCard" title="Filtres" class="user-list-filters mb-8" collapseAction refreshContentAction @refresh="resetColFilters" @remove="resetColFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Role</label>
          <v-select :options="roleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="roleFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Fonction</label>
          <v-select :options="fonctionOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="fonctionFilter" class="mb-4 md:mb-0" />
        </div>
      </div>
    </vx-card> -->

    <div class="vx-card p-6" id="user-list-body">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ usersData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : usersData.length }} of {{ usersData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button
            class="btn-primary ml-4 mb-2 sm:mb-0"
            color="primary"
            @click="popupUser=!popupUser">
            <!-- <feather-icon icon="UserPlusIcon" svgClasses="h-3 w-4" /> -->
            Nouvel Utilisateur

          </vs-button>


        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Recherche..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import passwordMeter from 'vue-simple-password-meter'

// Store Module

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

const identiteGetter = function (params) {
  return {nom: params.data.nom, photo: params.data.photo}
}
export default {
  components: {
    AgGridVue,
    vSelect,
    passwordMeter,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions
  },
  data () {
    return {
      IdOfficine: '',
      IdPersonel: '',
      login: '',
      password: null,
      password_confirm: null,
      photo: '',
      nom: '',
      prenoms: '',
      email: '',
      fonction:'',
      role: '',
      popupUser: false,
      popupUserUpdate: false,


      // Filter Options
      roleFilter: { label: 'All', value: 'all' },
      roleOptions: [
        { label: 'All', value: 'all' },
        { label: 'Admin', value: 'admin' },
        { label: 'Responsable', value: 'responsable' },
        { label: 'Stock', value: 'stock' },
        { label: 'Facturation', value: 'facturation' },
        { label: 'User', value: 'user' }
      ],
      fonctionFilter: {label: 'All', value: 'all'},
      fonctionOptions: [{ label: 'All', value: 'all' }],

      statusFilter: { label: 'All', value: 'all' },
      statusOptions: [

        { label: 'All', value: 'all' },
        { label: 'Active', value: 'active' },
        { label: 'Deactivated', value: 'deactivated' },
        { label: 'Blocked', value: 'blocked' }
      ],

      isVerifiedFilter: { label: 'All', value: 'all' },
      isVerifiedOptions: [
        { label: 'All', value: 'all' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],

      departmentFilter: { label: 'All', value: 'all' },
      departmentOptions: [
        { label: 'All', value: 'all' },
        { label: 'Sales', value: 'sales' },
        { label: 'Development', value: 'development' },
        { label: 'Management', value: 'management' }
      ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          width: 100,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'Nom',
          valueGetter: identiteGetter,
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Prénoms',
          field: 'prenoms',
          filter: true,
          width: 250
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: true,
          width: 250
        },
        {
          headerName: 'Login',
          field: 'login',
          filter: true,
          width: 150
        },
        {
          headerName: 'Fonction',
          field: 'fonction',
          filter: true,
          width: 150
        },
        {
          headerName: 'Role',
          field: 'role',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: 'Actions',
          field: '_id',
          width: 200,
          cellRendererFramework: 'CellRendererActions'
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      },
      Personnels: [],
      transiteObje: null
    }
  },
  watch: {
    roleFilter (obj) {
      this.setColumnFilter('role', obj.value)
    },
    fonctionFilter (obj) {
      this.setColumnFilter('fonction', obj.value)
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.login !== '' && this.password !== null && this.IdPersonel !== '' && this.fonction !== ''
    },
    fonctions () {
      return this.$store.state.fonction.fonctions
    },
    personnels () {
      return this.$store.state.personnel.personnels
    },
    usersData () {
      return this.$store.state.userManagement.users
    },
    /* usersCompleteData () {
      // return this.$store.getters['getUsersPlusPersData'](this.personnelData)
    }, */

    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    roles () {
      return this.$store.state.role.roles
    }
  },
  methods: {
    generatePassword () {
    },
    OnSelectPersonnel () {
      const personnelInfos = this.$store.getters['personnel/getPersonnelById'](this.IdPersonel)

      if (personnelInfos.CheminPhotoP[0]) this.photo = personnelInfos.CheminPhotoP[0]

      this.IdOfficine = personnelInfos.IdOfficine
      this.nom =  personnelInfos.NomPersonel
      this.prenoms = personnelInfos.PrenomPersonel
      this.email = personnelInfos.Adresse.EmailPersonel

    },
    creatUser () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdPersonel: this.IdPersonel,
        login: this.login,
        password: this.password,
        photo: this.photo,
        nom: this.nom,
        prenoms: this.prenoms,
        email: this.email,
        fonction: this.fonction,
        role: this.role
        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/addUser', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'Utilisateur sauvegardé',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.reset_data()
          this.$vs.loading.close()
          this.popupUser = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    reset_data () {
      this.IdOfficine = ''
      this.IdPersonel = ''
      this.login = ''
      this.password = this.password_confirm = null
      this.photo = ''
      this.nom = ''
      this.prenoms = ''
      this.email = ''
      this.fonction = ''
      this.role = ''
    },
    updateUser () {
      const payload = {
        idPersonnel: this.idPersonnel,
        idFonction: this.idFonction,
        Login: this.Login,
        passU: this.passU
        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/addUser', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.reset_data()
          this.$vs.loading.close()
          this.popupUser = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    //
    fetchUsers () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPersonnels () {
      this.$store.dispatch('personnel/getPersonnels')
        .then((resp) => {
          resp.data.forEach((item) => {
            this.transiteObje = {
              id: item._id,
              personnelFullName: `${item.NomPersonel} ${item.PrenomPersonel}`
            }
            this.Personnels.push(this.transiteObje)
            this.transiteObje = {}
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getfonctions () {
      this.$store.dispatch('fonction/getfonctions')
        .then((resp) => {
          resp.data.forEach((item) => {
            let trans = {label: item.Libelle_Fonct, value: item.Libelle_Fonct}
            this.fonctionOptions.push(trans)
            trans = {}
          })
        })
        .catch(err => {
          console.error(err)
        })

    },
    getRoles () {
      this.$store.dispatch('role/getRoles')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.fetchUsers()
    this.getPersonnels()
    this.getfonctions()
    this.getRoles()
    // console.log("Donnees completes User >> ",this.$store.getters.getUsersPlusPersData)
  }

}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

/* .header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
} */
</style>
