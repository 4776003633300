<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <vs-popup classContent="popup-example" title="Modifier un Utilisateur" :active.sync="popup">
        <div class="vx-row mb-6">

          <div class="vx-col w-full">
            <vs-input label="Login" class="w-full" type="text" v-validate="'required'" name="Login" v-model="login" />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="fonction" class="w-full" v-model="fonction" >
              <vs-select-item v-for="item in fonctions" :key="item._id" :value="item.Libelle_Fonct" :text="item.Libelle_Fonct"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="Role" class="w-full" v-model="role" >
              <vs-select-item v-for="item in roles" :key="item._id" :value="item.libelle" :text="item.libelle"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateUser" >Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="IdPersonel = login = paswword = confirm_password = fonction = role = ''; status = false;">Annuler</vs-button>
          </div>
        </div>
      </vs-popup>
      <vs-popup classContent="popup-example" title="Réinitialisation du mot de passe" :active.sync="popupPassword">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input label="Ancien mot de passe" class="w-full" type="password" v-validate="'required'" name="oldPassword" v-model="oldPassword" />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="Nouveau mot de passe" class="w-full" type="password" v-validate="'required'" name="newPassword" v-model="newPassword" />
            <span class="text-danger text-sm" v-show="errors.has('pass')">{{ errors.first('pass') }}</span>
            <password-meter :password="newPassword" />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input label="Confirme Mot de passe" class="w-full" type="password" v-validate="'required|confirmed:password'" data-vv-as="password" name="confirmPassword" v-model="confirmPassword" />
            <password-meter v-if="newPassword == confirmPassword" :password="confirmPassword" />
          </div>
        </div>
        <div class="flex justify-center">
          <vs-button class="mr-3 mb-2" @click="resetUserPassword" >Réinitialiser</vs-button>
          <vs-button color="warning" type="border" class="mb-2" @click="popupPassword=!popupPassword">Annuler</vs-button>
        </div>
      </vs-popup>
      <feather-icon icon="LockIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="resetPassword" />
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateUserShowForm" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
import passwordMeter from 'vue-simple-password-meter'
export default {
  name: 'CellRendererActions',
  components: {
    passwordMeter
  },
  data () {
    return {
      popup: false,
      popupPassword: false,
      IdOfficine: '',
      IdPersonel: '',
      login: '',
      photo: '',
      nom: '',
      prenoms: '',
      email: '',
      fonction:'',
      role: '',

      oldPassword: null,
      newPassword: null,
      confirmPassword: null
    }
  },
  computed:{
    validateForm () {
      return !this.errors.any() && this.loginU !== '' & this.passU !== '' && this.idPersonnel !== '' && this.idFonction !== ''
    },
    fonctions () {
      return this.$store.state.fonction.fonctions
    },
    roles () {
      return this.$store.state.role.roles
    }
  },
  methods: {
    resetPassword () {
      this.popupPassword = true
    },
    resetUserPassword () {
      const payload = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        id: this.params.value
        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/resetUserPassword', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'Modification sauvegardé',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.popupPassword = false
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error('error:', err)
          this.$vs.notify({
            title: 'error',
            text: 'Ancien mot de passe incorrect ou validation de mot de passe incorrecte',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },

    updateUserShowForm () {
      this.loginU = this.params.data.loginU
      this.passU = this.params.data.passU
      this.idPersonnel = this.params.data.idPersonnel
      this.userId = this.params.data._id
      this.popup = true
    },
    updateUser () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdPersonel: this.IdPersonel,
        login: this.login,
        photo: this.photo,
        nom: this.nom,
        prenoms: this.prenoms,
        email: this.email,
        fonction: this.fonction,
        role: this.role,
        id: this.params.value
        // status: this.status
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/updateUser', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'Modification sauvegardé',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.popup = false
          this.resete_data()
          this.fetchUsers()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupUserUpdate = false
        })
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: 'Vous allez supprimé ce utilisateur',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('userManagement/removeUser', this.params.value)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'primary',
        title: 'Utilisateur Supprimé',
        text: 'L\'Utilisateur selectionné a bien été supprimé'
      })
    },
    getfonctions () {
      this.$store.dispatch('fonction/getfonctions')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })

    },
    getPersonnels () {
      this.$store.dispatch('personnel/getPersonnels')
        .then((resp) => {
          const infos = this.$store.getters['userManagement/getUserById'](this.params.value)
          const personnel =  resp.data.find((p) => p._id === infos.IdPersonel)

          this.login = infos.login
          this.fonction = infos.fonction
          this.role = infos.role
          this.IdOfficine = personnel.IdOfficine
          this.nom =  personnel.NomPersonel
          this.prenoms = personnel.PrenomPersonel
          this.email = personnel.Adresse.EmailPersonel
          if (personnel.CheminPhotoP[0]) this.photo = personnel.CheminPhotoP[0]


        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchUsers () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getRoles () {
      this.$store.dispatch('role/getRoles')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getPersonnels()
    this.getfonctions()
    this.getRoles()
  }
}
</script>
